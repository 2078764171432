import React, { useRef, useEffect } from 'react';
import Course from "./Course";
import '../../style/Section.css';

export default function Section({ sectionTitle, displayCourses }) {
  const animationDuration = Math.random() * 120 + 60;

  return (
    <>
      <div className="sectionContainer">
        <div className="sectionTitle">
          <span>{sectionTitle}</span>
        </div>
        <div className="gridContainer" style={{ animation: `scroll-left ${animationDuration}s linear infinite` }}>
          {displayCourses.map((course) => (
            <div key={course.id} className="gridItem">
              <Course course={course} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}