import '../../App.css';
import '../../style/InfoPage.css';
import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import SaveIcon from '@mui/icons-material/Save';
import LogoutIcon from '@mui/icons-material/Logout';
import {AuthContext} from '../oAuth/Auth.js';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E1E1E1',
    },
    '&:hover fieldset': {
      borderColor: '#303030',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3A9FFC',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#E1E1E1',
  },
  '&.Mui-focused .MuiInputLabel-root': {
    color: '#303030',
  },
  '& .MuiOutlinedInput-input': {
    color: '#303030',
  },
  '& .MuiInputBase-input::placeholder': {
    color: '#E1E1E1',
  },
  width: '400px',
});

const CustomFormControl = styled(FormControl)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E1E1E1',
    },
    '&:hover fieldset': {
      borderColor: '#303030',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3A9FFC',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#E1E1E1',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#303030',
  },
  '& .MuiOutlinedInput-input': {
    color: '#303030',
  },
  '& .MuiInputBase-input::placeholder': {
    color: '#303030',
  },
  width: '180px',
});

const CustomButton = styled(Button)({
  backgroundColor: '#3A9FFC',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#303030',
  },
  '&:focus': {
    backgroundColor: '#3A9FFC',
  },
});

const LogoutButton = styled(Button)({
  backgroundColor: 'white', 
  color: '#E46060', 
  border: '1px solid #E46060', 
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
  '&:hover': {
    backgroundColor: '#E46060',  
    color: 'white',  
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
  },
  '&:focus': {
    backgroundColor: '#E46060',
    borderColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  width: '600px',
});

function InfoPage() {
  const [profileId, setProfileId] = useState(-1);
  const [userId, setUserId] = useState(-1);
  const [profileExists, setProfileExists] = useState(false);
  const [displayname, setDisplayname] = useState(null);
  const [affiliation, setAffiliation] = useState('');
  const [degree, setDegree] = useState('');
  const [year, setYear] = useState('');
  const [email, setEmail] = useState('');
  const [displayMsg, setDisplayMsg] = useState('');
  const { setAuthed } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleAffiliationChange = (event) => {
    setAffiliation(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    if (['alumni', 'professor', 'other'].includes(affiliation)) {
      setYear('other');
    }
  }, [affiliation]);

  useEffect(() => {
    const userInfo = async () => {
      setIsLoading(true);
      try {
        await getUserInfo();
        await getProfile();
      } catch (error) {
        console.error("Error fetching user information:", error);
      } finally {
        setIsLoading(false);
      }
    };
    userInfo();
  }, []);

  const headers = {
    "Content-Type": "application/json"
  };

  async function getUserInfo() {
    try {
      const response = await fetch("/api/user-info/", {
        headers: headers,
        credentials: "include"
      });
      const userInfo = await response.json();
      setUserId(userInfo.id);
      setEmail(userInfo.email);
    } catch {
      console.log("Something went wrong while fetching your account information.")
    };
  }

  async function getProfile() {
    const response = await fetch("/api/profiles/user/", {
      headers: headers,
      credentials: "include"
    });

    if (response.status === 401) {
      setDisplayMsg("Login to see account details.");
      setProfileExists(false);
    } else if (response.status === 404) {
      setProfileExists(false);
    } else {
      const profile = await response.json();
      setProfileExists(true);

      setProfileId(profile.id);
      setDisplayname(profile.display_name);
      setAffiliation(profile.ubc_affiliation);
      setDegree(profile.degree);
      setYear(profile.year);
    };
  }

  const payload = {
    "user": userId,
    "display_name": displayname ? displayname : null,
    "degree": degree,
    "year": year !== "other" ? year : null,
    "ubc_affiliation": affiliation
  };

  async function updateProfile() {
    const response = await fetch(`/api/profiles/${profileId}/`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(payload),
      credentials: "include"
    });
    
    if (response.ok) {
      toast.success("Profile updated.", {
        className: "toastMessage",
        hideProgressBar: false,
        autoClose: 4000,
        position: "bottom-right"
      });
    } else {
      toast.error("Profile could not be updated. Please fill in all required information.", {
        className: "toastMessage",
        hideProgressBar: false,
        autoClose: 4000,
        position: "bottom-right"
      });
    }
  }

  async function createProfile() {
    const response = await fetch("/api/profiles/", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
      credentials: "include"
    });
    if (response.ok) {
      toast.success("Profile created.", {
        className: "toastMessage",
        hideProgressBar: false,
        autoClose: 4000,
        position: "bottom-right"
      });
    } else {
      toast.error("Profile could not be created. Please fill in all required information.", {
        className: "toastMessage",
        hideProgressBar: false,
        autoClose: 4000,
        position: "bottom-right"
      });
    }
  }

  async function handleLogout() {
    const response = await fetch("/api/auth/logout/", { // change for deployment
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    });
    
    if (response.ok) {
      setAuthed(false);
      navigate("/");
      toast.success("Logged out.", {
        className: "toastMessage",
        hideProgressBar: false,
        autoClose: 4000,
        position: "bottom-right"
      });
    } else {
      toast.error("An error occurred while logging out.", {
        className: "toastMessage",
        hideProgressBar: false,
        autoClose: 4000,
        position: "bottom-right"
      });
    }
  }

  const accountDetailForms = () => {
    return (
      <>
        <div className="entryLine">
        <CustomTextField
          id="username"
          label="Username (32 Characters Max)"
          value={displayname}
          onChange={(e) => setDisplayname(e.target.value)}
          inputProps={{ maxLength: 32 }}
          placeholder="Enter your username"
          InputLabelProps={{ shrink: !!displayname }}
        />
          <CustomFormControl sx={{ marginLeft: '20px' }} variant="outlined">
            <InputLabel id="affiliation-select-label">UBC Affiliation (Required)</InputLabel>
            <Select
              labelId="affiliation-select-label"
              id="affiliation-select"
              value={affiliation}
              onChange={handleAffiliationChange}
              label="UBC Affiliation (Required) "
            >
              <MenuItem value="student">Student</MenuItem>
              <MenuItem value="alumni">Alumni</MenuItem>
              <MenuItem value="professor">Professor</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </CustomFormControl>
        </div>

        <div className="entryLine">
          <CustomTextField
            id="program"
            label="Program (64 Characters Max - Required)"
            value={degree}
            onChange={(e) => setDegree(e.target.value)}
            inputProps={{ maxLength: 64 }}
          />
          <CustomFormControl sx={{ marginLeft: '20px' }} variant="outlined">
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year"
              value={year}
              onChange={handleYearChange}
              label="Year"
              disabled={['alumni', 'professor', 'other'].includes(affiliation)}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6+</MenuItem>
              {['alumni', 'professor', 'other'].includes(affiliation) && (
                <MenuItem value="other">N/A</MenuItem>
              )}
            </Select>
          </CustomFormControl>
        </div>

        <div className="entryLine">
          <CustomTextField
            sx={{ width: '600px' }}
            id="email"
            label="Google Account"
            type="email"
            value={email}
            InputProps={{
              readOnly: true,
            }}
          />
        </div>

        <div className="entryLine">
          <Button sx={{ width: '600px', height: '44px' }} variant="contained" onClick={profileExists ? updateProfile : createProfile}>
            Save &nbsp;<SaveIcon />
          </Button>
        </div>

        <div className="entryLine">
          <LogoutButton onClick={handleLogout}>
            Logout &nbsp;<LogoutIcon />
          </LogoutButton>
        </div>
      </>
    );
  };

  const notLoggedIn = () => {
    return (
      <p>
        Login to see account details and write reviews!
      </p>
    );
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <CircularProgress />
        <p>Loading account information...</p>
      </div>
    );
  }

  return (
    <div className="infoPage">
      <h1 className="title">Your Account</h1>
      <div className="oneLine"></div>
      {displayMsg === "" ? accountDetailForms() : notLoggedIn()}
    </div>
  );
}

export default InfoPage;