import React, { useContext, useState } from 'react';
import '../../style/ReviewSection.css';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Button from '@mui/material/Button';
import { AuthContext } from '../oAuth/Auth.js';
import GoogleLoginButton from '../oAuth/GoogleLoginButton';
import CommentIcon from '@mui/icons-material/Comment';
import RedditIcon from '@mui/icons-material/Reddit';
import ReviewCard from './ReviewCard';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import RedditThreads from './RedditThreads.js';

const buttonStyles = {
    padding: '0px',
    color: 'white',
    borderRadius: '0',
    '& .MuiButton-root': { borderRadius: 0 },
};

const loginButtonStyle = {
    padding: '10px',
    color: 'white',
    backgroundColor: '#3D3D3D',
    borderRadius: '20px',
    '&:hover': {
        background: '#3D3D3D'
    }
};

function ReviewSection(props) {
    const { isAuthed } = useContext(AuthContext);
    const [selectedButton, setSelectedButton] = useState('comment');
    const courseId = props.courseId;
    const courseCode = courseId.slice(0, 4);
    const courseNum = courseId.slice(4, courseId.length);

    const handleToggle = (event, newSelectedButton) => {
        if (newSelectedButton !== null) {
            setSelectedButton(newSelectedButton);
        }
    };

    return (
        <div className="reviewSection">
            <div className="review2">
                <div className="reviewSectionHeader">
                    <ToggleButtonGroup
                        value={selectedButton}
                        exclusive
                        onChange={handleToggle}
                        aria-label="icon button group"
                        sx={{
                            '& .MuiToggleButton-root': {
                                padding: '12px',
                                fontSize: '1.2rem',
                                color: '#9e9e9e',
                                transition: 'background-color 0.15s, color 0.15s',
                                '&.Mui-selected': {
                                    backgroundColor: selectedButton === 'comment' ? '#3D3D3D' : '#FF5700',
                                    color: 'white',
                                },
                                '&.Mui-selected:hover': {
                                    backgroundColor: selectedButton === 'comment' ? '#3D3D3D' : '#FF5700',
                                    color: 'white',
                                },
                                '&:not(.Mui-selected):hover': {
                                    backgroundColor: selectedButton === 'comment' ? '#FF5700' : '#3D3D3D',
                                    color: 'white',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                            },
                            '& .MuiToggleButtonGroup-grouped': {
                                border: 'none',
                            }
                        }}
                    >
                        <ToggleButton
                            value="comment"
                            aria-label="comment icon"
                        >
                            <span style={{ fontSize: '16px' }}>Comments</span>
                            <CommentIcon fontSize="medium" />
                        </ToggleButton>
                        <ToggleButton
                            value="reddit"
                            aria-label="reddit icon"
                        >
                            <span style={{ fontSize: '16px' }}>r/ubc</span>
                            <RedditIcon fontSize="medium" />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div>
                    {isAuthed ? (
                        <Button
                            style={{ padding: '10px', color: 'white', backgroundColor: '#3D3D3D', borderRadius: '20px' }}
                            onClick={() => props.togglePopup()}
                        >
                            Write Course Review &nbsp; <RateReviewIcon />
                        </Button>
                    ) : (
                        <GoogleLoginButton sx={loginButtonStyle} />
                    )}
                </div>
            </div>
            <div className="blackLine"></div>
            {selectedButton === "comment" && props.reviews.map((review, index) => (
                <ReviewCard key={index} reviewData={review} />
            ))}
            {selectedButton === "reddit" &&
                <RedditThreads courseCode={courseCode} courseNum={courseNum} />
            }
            {props.reviews.length === 0 && selectedButton !== "reddit" &&
                <p className="emptyMessage">Be the first one to review this course!</p>
            }
            <div style={{ height: "12px" }} />
        </div>
    );
}

export default ReviewSection;