import React, { useEffect, useState, useRef } from 'react';
import { SearchResult } from './SearchResult';
import '../../style/Navbar.css'
import SearchIcon from '@mui/icons-material/Search';

export function SearchBar() {
    const [searchInput, setSearchInput] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedResultIndex, setSelectedResultIndex] = useState(-1);
    const resultsContainerRef = useRef(null);

    useEffect(() => {
        if (searchInput.trim() === "") {
            setSearchResults([]);
            setSelectedResultIndex(-1);
            return;
        }

        const debouncedSearch = setTimeout(() => {
            searchCourse(searchInput.toLowerCase())
                .then((data) => {
                    setSearchResults(data);
                    setSelectedResultIndex(-1);
                })
                .catch((e) => console.log(e));
        }, 200);
        return () => clearTimeout(debouncedSearch);
    }, [searchInput]);

    useEffect(() => {
        if (selectedResultIndex !== -1 && resultsContainerRef.current) {
            const selectedResultElement = resultsContainerRef.current.children[selectedResultIndex];
            if (selectedResultElement) {
                selectedResultElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }
        }
    }, [selectedResultIndex]);

    function handleKeyDown(e) {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            setSelectedResultIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : searchResults.length - 1));
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            setSelectedResultIndex(prevIndex => (prevIndex < searchResults.length - 1 ? prevIndex + 1 : 0));
        } else if (e.key === "Enter") {
            if (selectedResultIndex !== -1 && searchResults[selectedResultIndex]) {
                const { dept_id, course_number } = searchResults[selectedResultIndex];
                const courseId = `${dept_id.toUpperCase()}${course_number}`;
                const coursePageUrl = `/${courseId}`;
                window.location.href = coursePageUrl;
            }
        }
    }    

    async function searchCourse(query) {
        if (query.length > 15) return [];
        const baseURL = "/api/courses/search/?";
        const queryParams = `id=${sanitizeSearchInput(query)}`;
        const fetchURL = baseURL + queryParams;
        const response = await fetch(fetchURL);
        return response.json()
    }

    function sanitizeSearchInput(input) {
        let safeInput = input.replace(/\s/g, ''); // remove whitespace
        safeInput = safeInput.replace(/<[^>]*>?/gm, '');
        return encodeURIComponent(safeInput);
    }

    return (
        <div className="searchContainer">
          <input
            type="text"
            className={searchResults.length === 0 ? "searchBar" : "searchBarWithResults"}
            placeholder="let us know what you think about your courses!"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <div
            className={searchResults.length === 0 ? "resultsContainerNoResults" : "resultsContainer"}
            ref={resultsContainerRef}
          >
            {searchResults.map((result, index) => {
                return (
                    <SearchResult
                        key={result.id}
                        dept={result.dept_id.toUpperCase()}
                        courseNum={result.course_number}
                        isSelected={index === selectedResultIndex}
                    />
                );
            })}
          </div>
          <SearchIcon className="searchIcon" />
      </div>
    );
}