import React, { useState } from "react";
import { toast } from "react-toastify";
import "../../style/writeReview/CreateProfessorPopup.css";
import "../../style/writeReview/WriteReviewPopup.css";
import CloseIcon from '@mui/icons-material/Close';

export function CreateProfessorPopup(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const professorPayload = {
        first_name: firstName,
        last_name: lastName
    };

    async function createProfessorRequest() {
        // Check if the professor name already exists
        const response = await fetch("/api/professors/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (!response.ok) {
            toast.error("Something went wrong while fetching professors!", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 3000,
                position: "bottom-right"
            });
            return; // Exit the function if there was an error fetching professors
        }

        const professors = await response.json(); // Get the list of professors from the response
        const existingProfessor = professors.find(professor => professor.first_name === firstName && professor.last_name === lastName);
        if (existingProfessor) {
            toast.error("Professor name already exists! You can find it in the professor search bar", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 3000,
                position: "bottom-right"
            });
            return; // Exit the function if the professor already exists
        }

        // Proceed with adding the professor
        const createResponse = await fetch("/api/professors/", {
            method: "POST",
            body: JSON.stringify(professorPayload),
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (!createResponse.ok) {
            toast.error("Please fill out both first and last names", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 3000,
                position: "bottom-right"
            });
            return; // Exit the function if there was an error creating the professor
        }

        const newProfessor = await createResponse.json(); // Get the newly created professor from the response
        toast.success("Professor name added!", {
            className: "toastMessage",
            hideProgressBar: false,
            autoClose: 3000,
            position: "bottom-right"
        });
        // Add the newly created professor to the review
        props.addProfessor(newProfessor);
        props.togglePopup();
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            createProfessorRequest();
        }
    }

    return (
        <div className="createProfessorPopupContainer">
            <div className="overlay" onClick={props.togglePopup}></div>
            <div className="createProfessorPopup">
                <div className="cancelButton" onClick={props.togglePopup}>
                    <CloseIcon />
                </div>
                <div className="inputProfessorDetails">
                    <h2>Add New Professor</h2>
                    <div className="horizontalLine" />
                    <input
                        type="text"
                        placeholder="First name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    <input
                        type="text"
                        placeholder="Last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                </div>
                <button className="submitButton2" onClick={createProfessorRequest}>
                    Submit
                </button>
            </div>
        </div>
    );
}