import React, { useState, useEffect, useRef } from "react";
import "../../style/writeReview/WriteReviewPopup.css";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';

export function SearchProfessorField(props) {
    const [searchProfessorInput, setSearchProfessorInput] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedResultIndex, setSelectedResultIndex] = useState(-1);
    const searchResultsRef = useRef(null);

    useEffect(() => {
        if (searchProfessorInput.trim() === "") {
            setSearchResults([]);
            return;
        }

        const debouncedSearch = setTimeout(() => {
            searchProfessor(searchProfessorInput)
                .then((data) => setSearchResults(data))
                .catch((e) => console.log(e));
        }, 250);
        return () => clearTimeout(debouncedSearch);
    }, [searchProfessorInput]);

    function sanitizeSearchInput(input) {
        const safeInput = input.replace(/<[^>]*>?/gm, "");
        return encodeURIComponent(safeInput);
    }

    async function searchProfessor(query) {
        const baseURL = "/api/professors/search/?";
        const stanitizedInput = sanitizeSearchInput(query.toLowerCase());
        const queryParams = `name=${stanitizedInput}`;
        const fetchURL = baseURL + queryParams;
        const response = await fetch(fetchURL);
        return response.json();
    }

    function resetFields() {
        setSearchProfessorInput("");
        setSearchResults([]);
        setSelectedResultIndex(-1);
    }

    function handleClick(professor) {
        setSearchProfessorInput(professor.full_name);
        props.addProfessor(professor);
        resetFields();
    }
    

    function handleKeyDown(e) {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            setSelectedResultIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : searchResults.length - 1
            );
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            setSelectedResultIndex((prevIndex) =>
                prevIndex < searchResults.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === "Enter") {
            e.preventDefault();
            if (selectedResultIndex !== -1) {
                handleClick(searchResults[selectedResultIndex]);
            }
        }
    }

    useEffect(() => {
        if (searchResultsRef.current && selectedResultIndex !== -1) {
            searchResultsRef.current.children[selectedResultIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [selectedResultIndex]);

    return (
        <div>
            <div className="inputField">
                <div className="inputFieldHeader">
                    <h2>Professor(s)</h2>
                    <div onClick={props.toggleAddProfessorPopup}>
                        <IconButton size="small">
                            <AddCircleOutlineIcon fontSize="small" style={{ color: "#7d7979" }} />
                        </IconButton>
                    </div>
                </div>
                <input
                    type="text"
                    placeholder="Gregor Kiczales"
                    value={searchProfessorInput}
                    onChange={(e) => setSearchProfessorInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    
                />
                <div className={searchResults.length === 0 ? "searchResultsEmpty" : "searchResults"} ref={searchResultsRef}>
                    {searchResults.map((result, index) => {
                        return (
                            <div
                                key={result.id}
                                className={index === selectedResultIndex ? "professorSearchResult selected" : "professorSearchResult"}
                                onClick={() => handleClick(result)}
                            >
                                {result.full_name}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}