import '../../style/CourseAverage.css';

function CourseAverage(props) {

    return (
        <div className = "courseAverageContainer">
            <h2>COURSE AVERAGE</h2>
            <h1 className="courseAverage">{props.average.toFixed(1)}</h1>
        </div>
    );
}

export default CourseAverage;