import React, { useState } from 'react';
import Button from '@mui/material/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PercentIcon from '@mui/icons-material/Percent';

/**
 * Course has format: {id, course_number, description, average, dept_id}
    * e.g {id: "CPSC110", description: "Data structures...", dept_id: "cpsc"}
 */
function Course({course}) {
    const courseId = `${course.dept_id.toUpperCase()}${course.course_number}`;

    const deptColorMap = {
        "aanb": "84C9E2",
        "adhe": "5E8C31",
        "afst": "8B4513",
        "agec": "FFA500",
        "amne": "4B5320",
        "anae": "4682B4",
        "anat": "800000",
        "ansc": "008080",
        "anth": "8B4513",
        "apbi": "008000",
        "appp": "007FFF",
        "apsc": "A6192E",
        "arbc": "FFD700",
        "arch": "808080",
        "arcl": "CD853F",
        "arst": "663399",
        "arth": "800080",
        "arts": "00CED1",
        "asia": "FF7F50",
        "asic": "8FBC8F",
        "astr": "191970",
        "astu": "FF69B4",
        "atsc": "87CEEB",
        "audi": "6495ED",
        "ba"  : "4B0082",
        "baac": "4B0082",
        "babs": "4B0082",
        "baen": "4B0082",
        "bafi": "4B0082",
        "bahr": "4B0082",
        "bait": "4B0082",
        "bala": "4B0082",
        "bama": "4B0082",
        "bams": "4B0082",
        "bapa": "4B0082",
        "basc": "4B0082",
        "basm": "4B0082",
        "baul": "4B0082",
        "bioc": "2E8B57",
        "biof": "9932CC",
        "biol": "006400",
        "biot": "00FF00",
        "bmeg": "FF4500",
        "bota": "228B22",
        "brdg": "6495ED",
        "busi": "57B748",
        "caps": "FFA07A",
        "ccfi": "007BA7",
        "ccst": "708090",
        "cdst": "C8102E",
        "ceen": "50C878",
        "cell": "007BA7",
        "cens": "FFBF00",
        "chbe": "4169E1",
        "chem": "800020",
        "chil": "FFB6C1",
        "chin": "DC143C",
        "cics": "FF00FF",
        "civl": "4682B4",
        "clch": "DC143C",
        "clst": "FFD700",
        "cnps": "87CEEB",
        "cnrs": "DEB887",
        "cnto": "FF0000",
        "coec": "57B748",
        "cogs": "9966CC",
        "cohr": "57B748",
        "cons": "228B22",
        "comm": "57B748",
        "cpen": "C0C0C0",
        "cpsc": "4169E1",
        "crwr": "800080",
        "csis": "FF69B4",
        "cspw": "0077B6",
        "dani": "FF6347",
        "dent": "7ACC7A",
        "derm": "ADD8E6",
        "dhyg": "87CEEB",
        "dpas": "000080",
        "eced": "E75480",
        "econ": "4169E1",
        "edcp": "708090",
        "edst": "FFFF00",
        "educ": "4169E1",
        "eece": "9FA8A7",
        "elec": "DAA520",
        "emba": "8FBC8F",
        "emer": "DC143C",
        "ends": "8B4513",
        "engl": "273746",
        "enph": "808080",
        "envr": "228B22",
        "eosc": "65CCA9",
        "epse": "4169E1",
        "etec": "0080FF",
        "exch": "0077BE",
        "exgr": "0077BE",
        "fhis": "0072BB",
        "fipr": "B22222",
        "fish": "0077BE",
        "fist": "000000",
        "fmed": "4B0082",
        "fmpr": "FFD700",
        "fmst": "FF69B4",
        "fnel": "FF2400",
        "fnh" : "AC9A76",
        "fnis": "FF2400",
        "food": "FFD700",
        "fopr": "8B4513",
        "fre" : "DAA520",
        "fren": "6495ED",
        "frst": "228B22",
        "gbpr": "228B22",
        "gene": "4169E1",
        "geob": "008000",
        "geog": "008000",
        "geos": "008000",
        "germ": "000000",
        "gpp" : "003366",
        "grek": "FFD700",
        "grs" : "006400",
        "grsj": "800080",
        "gsat": "4169E1",
        "hebr": "FFD700",
        "hgse": "0077BE",
        "hinu": "D2691E",
        "hist": "800020",
        "hunu": "FFA500",
        "iar" : "FFD700",
        "igen": "4D8FAC",
        "inde": "A10022",
        "inds": "800080",
        "info": "1E90FF",
        "isci": "4D8FAC",
        "ital": "CD5C5C",
        "itst": "CD5C5C",
        "iwme": "7FFFD4",
        "japn": "CC99A2",
        "jrnl": "000000",
        "kin" : "008000",
        "korn": "1E90FF",
        "lais": "1560BD",
        "larc": "8B4513",
        "laso": "1E90FF",
        "last": "FF6347",
        "latn": "FF6347",
        "law" : "1E90FF",
        "lfs" : "DA9100",
        "libe": "1560BD",
        "libr": "1560BD",
        "ling": "FF00FF",
        "lled": "FF7F50",
        "math": "DC143C",
        "mdvl": "808080",
        "mech": "4682B4",
        "medd": "4D8FAC",
        "medg": "CC99A2",
        "medi": "CC99A2",
        "micb": "4CAF50",
        "midw": "FF1493",
        "mine": "FFD700",
        "mrne": "0077BE",
        "mtrl": "808080",
        "musc": "FF0000",
        "name": "0077BE",
        "nest": "8B4513",
        "nrsc": "FF6F61",
        "nurs": "FF69B4",
        "obst": "FFC0CB",
        "onco": "5DADE2",
        "opth": "4682B4",
        "ornt": "4D8FAC",
        "orpa": "4D8FAC",
        "paed": "ADD8E6",
        "path": "1E90FF",
        "pcth": "6495ED",
        "pers": "F4C430",
        "phar": "FF6666",
        "phil": "87CEEB",
        "phrm": "6495ED",
        "phth": "66CC99",
        "phyl": "66CC99",
        "phys": "1E90FF",
        "plan": "808080",
        "plnt": "008000",
        "poli": "4169E1",
        "pols": "FFBF00",
        "port": "FFBF00",
        "prin": "7AB9D4",
        "psyc": "6A0DAD",
        "psyt": "ADD8E6",
        "punj": "FF9933",
        "relg": "7AB9D4",
        "rgla": "FFD700",
        "rhsc": "9370DB",
        "rmes": "1E90FF",
        "rmst": "FF4500",
        "rsot": "FF4500",
        "russ": "A52A2A",
        "sans": "FF9933",
        "scan": "0066CC",
        "scie": "1E90FF",
        "slav": "FFBF00",
        "soci": "008000",
        "soil": "8B4513",
        "sowk": "800080",
        "span": "f6b512",
        "spha": "FF6347",
        "spph": "FF6347",
        "stat": "4682B4",
        "sts" : "1E90FF",
        "surg": "4169E1",
        "swed": "4169E1",
        "test": "87CEEB",
        "thtr": "FF4500",
        "udes": "808080",
        "ufor": "228B22",
        "urst": "808080",
        "ursy": "4682B4",
        "vant": "FFA500",
        "vgrd": "FFA500",
        "visa": "FF0000",
        "vurs": "FF0000",
        "wood": "8B4513",
        "wrds": "000080",
        "writ": "000080",
        "zool": "6B8E23",
        "enve": "4CAF50",
        "rgst": "FFA500",
    }

    const deptColor = deptColorMap[course.dept_id.toLowerCase()];

    const courseStyle = {
        paddingTop: "1rem",
        width: "100%",
        height: "100%",
        borderRadius: "0px 5px 0px 5px",
        fontSize: "3rem",
        textAlign: "center",
        fontWeight: "bold",
        background: `linear-gradient(to right, #${deptColor} 5px, transparent 7px)`,
        color: `#${deptColor}`,
        transition: "transform 0.07s ease, background-color 0.5s, box-shadow 0.5s ease",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        backgroundColor: "white",
    };

    const gradeStyle = {
        color: `#${deptColor}`,
    }

    const courseHoverStyle = {
        ...courseStyle,
        transform: "scale(1.05)",
        cursor: "pointer",
        backgroundColor: `#${deptColor}`,
        color: "#fbfbfb",
    }

    const defaultTextStyle = {
        color: `#${deptColor}`,
        textDecoration: "none"
    }

    const changedTextStyle = {
        color: "white",
        textDecoration: "none"
    }

    const [hoverStyle, setHoverStyle] = useState(courseStyle);
    const [hoverTextStyle, setHoverTextStyle] = useState(defaultTextStyle);
    
    const [courseTitle, setCourseTitle] = useState("");  
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const courseTitleStyle = {
        fontSize: "1rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginLeft: "12px",
        marginRight: "6px",
    };

    const containerStyle = {
        ...courseStyle,
        ...(isHovered ? courseHoverStyle : {}),
    };

    const handleClick = () => {
        window.location.href = `/${courseId}`;
    };

    return (
        <div
            style={containerStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <a
                style={isHovered ? changedTextStyle : defaultTextStyle}
                className="courseName"
            >
                {courseId}
            </a>
            <div style={courseTitleStyle}>
                {course.full_name}
            </div>
                <Button
                    sx={{
                        color: isHovered ? 'white' : `#7d4bbd`,
                        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
                    }}
                >
                    <FavoriteIcon />&nbsp;{isNaN(course.avg_exp_rating) ? "0" : (course.avg_exp_rating / 100).toFixed(1)}
                </Button>
                <Button
                    sx={{
                        color: isHovered ? 'white' : `#606de4`,
                        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
                    }}
                >
                    <FitnessCenterIcon />&nbsp;{isNaN(course.avg_diff_rating) ? "0" : (course.avg_diff_rating / 100).toFixed(1)}
                </Button>
                <Button
                    sx={{
                        color: isHovered ? 'white' : `#ce599f`,
                        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
                    }}
                >
                    <AccessTimeFilledIcon />&nbsp;{isNaN(course.avg_workload) ? "0" : (course.avg_workload / 100).toFixed(1)}
                </Button>
                <Button
                    sx={{
                        color: isHovered ? 'white' : `#e46060`,
                        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
                    }}
                >
                    <PercentIcon />&nbsp;{isNaN(course.average) ? "N/A" : (course.average / 100).toFixed(1)}
                </Button>
        </div>
    );     
};

export default Course;