import React, { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from "@react-oauth/google";
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import {AuthContext, UserContext} from "./Auth";
import { toast } from "react-toastify";

export default function GoogleLoginButton({sx}) {
    const {setAuthed} = useContext(AuthContext);
    const {userId, setUserId} = useContext(UserContext);
    const navigate = useNavigate();

    async function exchange(code) {
        const response = await fetch("/api/auth/exchange/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "code": code
            })
        });
        const parsedRes = await response.json()
        return parsedRes.access_token;
    }

    async function handleSuccess(response) {
        try {
            const access_token = await exchange(response.code);
            const res = await fetch("/api/auth/google/", { // change for deployment
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "access_token": access_token
                })
            });
            
            if (res.ok) {
                const parsedRes = await res.json();
                console.log(parsedRes);
                const userId = parsedRes.user.pk;
                console.log(userId);
                setUserId(userId);
                setAuthed(true);
                toast.success("Logged in.", {
                    className: "toastMessage",
                    hideProgressBar: false,
                    autoClose: 4000,
                    position: "bottom-right"
                });

                if (!await profileExists()) {
                    navigate("/account");
                }
            }
            
        } catch (err) {
            toast.error("An error occurred while logging in.", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 4000,
                position: "bottom-right"
            });
            console.log("Error while making OAuth Request:", err);
        }
    }

    async function profileExists() {
        const response = await fetch("/api/profiles/user/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.ok;
    }

    function handleFailure(response) {
        toast.error("An error occurred while logging in.", {
            className: "toastMessage",
            hideProgressBar: false,
            autoClose: 4000,
            position: "bottom-right"
        });
        console.log("Error:", response);
    }

    const handleLogin = useGoogleLogin({
        onSuccess: handleSuccess,
        onError: handleFailure,
        scope: "profile email",
        flow: "auth-code"
    })
 
    return (
        <Button onClick={handleLogin} variant="text" sx={sx} className="containedText">
          Login to write course review &nbsp;<GoogleIcon/>
        </Button>
    );
}