import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  zIndex: 2100,
}));

const CustomUl = styled('ul')({
  padding: 0,
  margin: 0,
  marginLeft: '16px',
  paddingTop: '2px',
});

export default function ReviewGuidelines() {
  const [reviewGuidelinesOpen, setReviewGuidelinesOpen] = React.useState(false);

  const handleTogglePopup = () => {
    setReviewGuidelinesOpen(!reviewGuidelinesOpen);
  };

  return (
    <React.Fragment>
      <Tooltip 
        title={
          <React.Fragment>
            <strong>Don't know what to write? Try these!</strong>
            <CustomUl>
              <li>What was the course about? Was it fun?</li>
              <li>How was the timeline? Any important deadline?</li>
              <li>Was there any useful tip?</li>
              <li>How was the quality of the teaching team?</li>
              <li>Would you recommend this course to others?</li>
            </CustomUl>
          </React.Fragment>
        } 
        PopperProps={{ style: { zIndex: 2200 } }}
      >
          <InfoIcon onClick={handleTogglePopup} style={{color: "7d7979", marginLeft: "auto", fontSize: "20px", transform: 'translateY(2px)'}} />
      </Tooltip>
    </React.Fragment>
  );
}