import React, { useState, useEffect, useRef } from 'react';
import { SearchResult } from '../navbar/SearchResult';
import SearchIcon from '@mui/icons-material/Search';
import '../../style/HomeSearchBar.css';

export function HomeSearchBar() {
    const [searchInput, setSearchInput] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedResultIndex, setSelectedResultIndex] = useState(-1);
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const resultsContainerRef = useRef(null);

    const placeholderTexts = [
        "Find your next favorite course here!",
        "If you like it, leave a course review!",
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setIsFadingOut(true);
            setTimeout(() => {
                setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholderTexts.length);
                setIsFadingOut(false);
            }, 1000);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (searchInput.trim() === "") {
            setSearchResults([]);
            setSelectedResultIndex(-1);
            return;
        }

        const debouncedSearch = setTimeout(() => {
            searchCourse(searchInput.toLowerCase())
                .then((data) => {
                    setSearchResults(data);
                    setSelectedResultIndex(-1);
                })
                .catch((e) => console.log(e));
        }, 200);

        // Reset scroll position when search input changes
        if (resultsContainerRef.current) {
            resultsContainerRef.current.scrollTop = 0;
        }

        return () => clearTimeout(debouncedSearch);
    }, [searchInput]);

    useEffect(() => {
        if (selectedResultIndex !== -1 && resultsContainerRef.current) {
            const container = resultsContainerRef.current;
            const selectedElement = container.children[selectedResultIndex];
            
            if (selectedElement) {
                const containerRect = container.getBoundingClientRect();
                const elementRect = selectedElement.getBoundingClientRect();
                
                if (elementRect.bottom > containerRect.bottom) {
                    container.scrollTop += elementRect.bottom - containerRect.bottom;
                } else if (elementRect.top < containerRect.top) {
                    container.scrollTop -= containerRect.top - elementRect.top;
                }
            }
        }
    }, [selectedResultIndex]);

    function handleKeyDown(e) {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            setSelectedResultIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : searchResults.length - 1));
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            setSelectedResultIndex(prevIndex => (prevIndex < searchResults.length - 1 ? prevIndex + 1 : 0));
        } else if (e.key === "Enter") {
            if (selectedResultIndex !== -1 && searchResults[selectedResultIndex]) {
                const { dept_id, course_number } = searchResults[selectedResultIndex];
                const courseId = `${dept_id.toUpperCase()}${course_number}`;
                const coursePageUrl = `/${courseId}`;
                window.location.href = coursePageUrl;
            }
        }
    }

    function handleInputChange(e) {
        setSearchInput(e.target.value);
        setSelectedResultIndex(-1);
    }

    async function searchCourse(query) {
        if (query.length > 15) return [];
        const baseURL = "/api/courses/search/?";
        const queryParams = `id=${sanitizeSearchInput(query)}`;
        const fetchURL = baseURL + queryParams;
        const response = await fetch(fetchURL);
        return response.json();
    }

    function sanitizeSearchInput(input) {
        let safeInput = input.replace(/\s/g, '');
        safeInput = safeInput.replace(/<[^>]*>?/gm, '');
        return encodeURIComponent(safeInput);
    }

    return (
        <div className="homeSearchContainer">
            <SearchIcon className="searchIcon2" />
            <div className="homeSearchBarContainer">
                <input
                    type="text"
                    className={searchResults.length === 0 ? "homeSearchBar" : "homeSearchBarWithResults"}
                    placeholder=""
                    value={searchInput}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
                {!isFocused && searchInput.trim() === "" && (
                    <span className={`searchPlaceholder ${isFadingOut ? 'fadeOut' : 'fadeIn'}`}>
                        {placeholderTexts[placeholderIndex]}
                    </span>
                )}
            </div>
            <div
                className={searchResults.length === 0 ? "homeResultsContainerNoResults" : "homeResultsContainer"}
                ref={resultsContainerRef}
            >
                {searchResults.map((result, index) => (
                    <SearchResult
                        key={result.id}
                        dept={result.dept_id.toUpperCase()}
                        courseNum={result.course_number}
                        isSelected={index === selectedResultIndex}
                    />
                ))}
            </div>
        </div>
    );
}