import React, { useEffect, useRef } from 'react';
import Resource from './Resource';
import '../../style/Section.css';

export default function ResourceSection({ sectionTitle, displayResources }) {
  const animationDuration = 320;

  return (
    <>
      <div className="sectionContainer">
        <div className="sectionTitle">
          <h1>{sectionTitle}</h1>
        </div>
        <div className="gridContainer" style={{ animation: `scroll-left ${animationDuration}s linear infinite` }}>
          {displayResources.map((resource, index) => (
            <div key={index} className="gridItem">
              <Resource
                resourceName={resource.name}
                url={resource.url}
                color={resource.color}
                title={resource.title} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}