import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "../../style/writeReview/WriteReviewPopup.css";
import { RatingField } from "./RatingField";
import { SearchCourseField } from "./SearchCourseField";
import { SearchProfessorField } from "./SearchProfessorField";
import { ProfessorList } from "./ProfessorList";
import { CreateProfessorPopup } from "./CreateProfessorPopup";
import CloseIcon from '@mui/icons-material/Close';
import MicIcon from '@mui/icons-material/Mic';
import ReviewGuidelines from "./ReviewGuidelines";
import Button from '@mui/material/Button';
import VoiceButton from "./VoiceButton";

export function WriteReviewPopup({ togglePopup }) {
    const [createProfessorPopupVisible, setCreateProfessorPopupVisible] = useState(false);
    const [course, setCourse] = useState("");
    const [professors, setProfessors] = useState([]);
    const [expRating, setExpRating] = useState(-1);
    const [diffRating, setDiffRating] = useState(-1);
    const [wkldRating, setWkldRating] = useState(-1);
    const [comments, setComments] = useState("");
    const [isVoiceActive, setIsVoiceActive] = useState(false);

    const popupRef = useRef(null);

    function addProfessorToReview(professor) {
        setProfessors(prevProfessors => [...prevProfessors, professor]);
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 27) {
                togglePopup();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                togglePopup();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        
        document.body.style.overflow = "hidden";

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleClickOutside);
            
            document.body.style.overflow = "auto";
        };
    }, [togglePopup]);

    function getProfessorIds() {
        return professors.map((prof) => prof.id);
    }

    function removeProfessor(professorId) {
        const newProfessors = professors.filter((prof) => prof.id !== professorId);
        setProfessors(newProfessors);
    }

    const payload = {
        "reviewer": null,
        "difficulty_rating": diffRating * 100,
        "experience_rating": expRating * 100,
        "workload": wkldRating * 100,
        "content": comments,
        "is_anonymous": false,
        "grade": null,
        "sampleData": false,
        "course": course,
        "add_professor_ids": getProfessorIds(professors)
    }

    async function handleSubmit() {
        if (professors.length === 0) {
            toast.error("Please select at least one professor. You can add unavailable professor by clicking on the + button", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 4000,
                position: "bottom-right"
            });
            return;
        }

        const headers = {
            "Content-Type": "application/json",
        }

        const request = await fetch("/api/reviews/", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
            credentials: "include"
        });
    
        if (request.ok) {
            toast.success("Review successfully submitted!", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 4000,
                position: "bottom-right"
            });
            togglePopup();
        } else if (request.status === 401) {
            toast.error("You must be logged in to write reviews.", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 3000,
                position: "bottom-right"
            });
        } else {
            toast.error("Something went wrong while submitting your review!", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 3000,
                position: "bottom-right"
            });
        }
    }

    return (
        <div className="overlayWriteReviewContainer">
            <div className="writeReviewContainer" ref={popupRef}>
                <div className="cancelButton" onClick={togglePopup}>
                    <CloseIcon />
                </div>
                <div className="writeReviewContainerElements">
                    {createProfessorPopupVisible &&
                    <CreateProfessorPopup 
                        togglePopup={() => setCreateProfessorPopupVisible(!createProfessorPopupVisible)} 
                        addProfessor={addProfessorToReview}
                        professors={professors}
                    />
                    }
                    <h1>Course Review <ReviewGuidelines /></h1>
                    <div className="horizontalLine" />
                    <div className="fieldContainer">
                        <div className="inputFieldContainer">
                            <SearchCourseField setCourse={setCourse} />
                            <SearchProfessorField
                                addProfessor={addProfessorToReview}
                                toggleAddProfessorPopup={(e) => setCreateProfessorPopupVisible(true)}
                            />
                            <ProfessorList professorList={professors} removeProfessor={removeProfessor} />
                        </div>
                    </div>
                    <div className="ratingsContainer">
                        <h2>Ratings</h2>
                        <div className="ratingInputsContainer">
                            <RatingField
                                mainBgColor="7D4BBD"
                                inputBgColor="9B69DA"
                                title="EXPERIENCE"
                                placeholder="/5.0"
                                rating={expRating}
                                setRating={setExpRating}
                            />
                            <RatingField
                                mainBgColor="CE5975"
                                inputBgColor="E47691"
                                title="DIFFICULTY"
                                placeholder="/5.0"
                                rating={diffRating}
                                setRating={setDiffRating}
                            />
                            <RatingField
                                mainBgColor="606DE4"
                                inputBgColor="7280FF"
                                title="WORKLOAD"
                                placeholder="hr/wk"
                                rating={wkldRating}
                                setRating={setWkldRating}
                            />
                        </div>
                    </div>
                    <div className="recordButton">
                        <VoiceButton setComments={setComments} isVoiceActive={isVoiceActive} setIsVoiceActive={setIsVoiceActive}/>
                    </div>
                    <div className="inputContent">
                        <textarea
                            placeholder="Type or click the button above to review by voice!"
                            value={comments}
                            onChange={(e) => {setComments(e.target.value)}}
                        />
                    </div>
                    <div className="actionButtonsContainer">
                        <button
                            className="submitButton"
                            onClick={handleSubmit}
                            disabled={isVoiceActive}
                            style={isVoiceActive ? { opacity: "0.1"} : {}}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}