import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ReportIcon from '@mui/icons-material/Report';

function CourseReq(props) {
  const handleReportClick = () => {
    window.open('https://docs.google.com/forms/d/1X1BFwopx9KTCzWm4TauzPqpuep-KqZWJHUmtlF8D29c', '_blank');
  };

  return (
    <div className="additionalInfo">
        <h2>
            Course Information
            <Tooltip title="Report wrong/missing information">
                <IconButton onClick={handleReportClick}>
                  <ReportIcon fontSize="small" style={{ color: '#BB4040' }} />
                </IconButton>
            </Tooltip>
        </h2>
      <div className="requirementInfo">
        <p>Credits: {props.cred || "Unavailable"}</p>
        <p>Description: {props.desc || "Unavailable"}</p>
        <p>Prerequisites or recommended: {props.preq || "None"}</p>
        <p>Corequisites: {props.creq || "None"}</p>
        <p>
          {props.courseId || "This course"} is required or recommended for:{" "}
          {props.depn || "None"}
        </p>
      </div>
    </div>
  );
}

export default CourseReq;