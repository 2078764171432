import React, { useContext } from 'react';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import {AuthContext, UserContext} from "../oAuth/Auth";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

function ReviewCard(props) {
    const {isAuthed} = useContext(AuthContext);
    const {userId} = useContext(UserContext);
    const reviewData = props.reviewData;
    const postDate = new Date(reviewData.post_date);
    const formattedDate = `${postDate.getFullYear()}-${postDate.getMonth() + 1}-${postDate.getDate()}`;

    const profArr = reviewData.professors;
    const profNames = profArr.map((prof) => prof.full_name);

    const reviewerInfo = () => {
        const data = reviewData.reviewer?.profile;
        if (data) {
            let msg = data.degree || '';
            if (data.ubc_affiliation && data.ubc_affiliation.toLowerCase() !== "student") {
                msg += ` ${data.ubc_affiliation}`;
            }
            if (data.year) {
                msg += `, year ${data.year}`;
            }
            return msg.trim() || null;
        }
        return null;
    };

    async function deleteReview() {
        const response = await fetch(`/api/reviews/${reviewData.id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.ok) {
            toast.success("Successfully deleted review.", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 4000,
                position: "bottom-right"
            });
        } else if (response.status === 401 || response.status === 403) {
            toast.error("You do not have permission to delete this review.", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 4000,
                position: "bottom-right"
            }); 
        } else {
            toast.error("Something went wrong while deleting review.", {
                className: "toastMessage",
                hideProgressBar: false,
                autoClose: 4000,
                position: "bottom-right"
            });  
        }
    }

    return (
        <div className="reviewCard">
            <div className="firstLine">
                <p className="reviewer">
                    {reviewData.reviewer?.profile?.display_name || "anonymous"}
                    {reviewerInfo() && (
                        <span className="reviewerProfile">
                            &nbsp;{reviewerInfo().toLowerCase()}
                        </span>
                    )}
                </p>
                <div className="uploadedTimeContainer">
                    <div>
                        <p className="uploadedTime">{formattedDate}</p>
                    </div>
                    {isAuthed && userId === reviewData.reviewer?.id && <div className="deleteReview" onClick={deleteReview}>
                        <IconButton>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </div>}
                </div>
            </div>
            <div className="secondLine">
                <div className="professorReviewed">
                    <p>
                        Professor(s): {profNames.length > 0 ? (
                            profNames.map((name, index) => (
                                <React.Fragment key={index}>
                                    {name}{' '}
                                    <a 
                                        href={`https://www.ratemyprofessors.com/search/professors/1413?q=${encodeURIComponent(name)}`} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        <Tooltip title="Find on Rate My Professors" placement="top">
                                            <PersonSearchIcon 
                                                sx={{ 
                                                    color: '#7d7979',
                                                    verticalAlign: 'middle', 
                                                    marginBottom: '8px', 
                                                    cursor: 'pointer',
                                                    transition: 'color 0.3s, transform 0.3s',
                                                    '&:hover': {
                                                        color: 'rgb(61, 61, 61)',
                                                    },
                                                }} 
                                            />
                                        </Tooltip>
                                    </a>
                                    {index < profNames.length - 1 && ', '}
                                </React.Fragment>
                            ))
                        ) : (
                            "None"
                        )}
                    </p>
                </div>
                <div className="userInfoContainer">
                    <div className="userExperienceContainer">
                        <p className="experience">EXPERIENCE {reviewData.experience_rating / 100}/5</p>
                    </div>
                    <div className="userDifficultyContainer">
                        <p className="difficulty">DIFFICULTY {reviewData.difficulty_rating / 100}/5</p>
                    </div>
                    <div className="userWorkloadContainer">
                        <p>WORKLOAD {reviewData.workload / 100} hr/wk</p>
                    </div>
                </div>
            </div>
            <p className="comment">{reviewData.content}</p>
        </div>
    );
}

export default ReviewCard;