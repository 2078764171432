import '../../App.css';
import React, { useEffect, useState } from 'react';
import '../../style/CoursePage.css';
import CourseName from '../coursePage/CourseName';
import Grade from '../coursePage/Grade';
import CourseAverage from '../coursePage/CourseAverage';
import ReviewSection from '../coursePage/ReviewSection';
import CourseReq from '../coursePage/CourseReq';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

function CoursePage(props) {
  const { courseId } = useParams();

  const [experienceGrade, setExperienceGrade] = useState(0);
  const [difficultyGrade, setDifficultyGrade] = useState(0);
  const [workloadGrade, setWorkloadGrade] = useState(0);
  const [average, setAverage] = useState(0);
  const [cred, setcred] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [preq, setPreq] = useState("");
  const [creq, setCreq] = useState("");
  const [depn, setDepn] = useState("");
  const [desc, setDesc] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  function populateFields(courseData) {
    setExperienceGrade(courseData.avg_exp_rating / 100);
    setDifficultyGrade(courseData.avg_diff_rating / 100);
    setWorkloadGrade(courseData.avg_workload / 100);
    setCourseName(courseData.full_name);
    setAverage(courseData.average / 100);
    setcred(courseData.cred);
    setPreq(courseData.preq);
    setCreq(courseData.creq);
    setDesc(courseData.description);
    setDepn(courseData.depn);
  }

  async function fetchCourseData() {
    const response = await fetch(`/api/courses/${courseId.toLowerCase()}/`);
    return response.json();
  }

  async function fetchCourseReviews() {
    const response = await fetch(`/api/reviews/get/?id=${courseId.toLowerCase()}`);
    return response.json();
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const [courseData, courseReviews] = await Promise.all([
          fetchCourseData(),
          fetchCourseReviews()
        ]);
        populateFields(courseData);
        setReviews(courseReviews);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [courseId]);
  
  if (isLoading) {
    return (
      <div className="loading-container">
        <CircularProgress />
        <p>Loading course information...</p>
      </div>
    );
  }

  return (
    <>
      <div className="coursePageTitle">
        <div className="overallGrade">
          <CourseName courseName={courseName} courseId={courseId}/>
          <div className="courseRatings">
            <Grade experienceGrade={experienceGrade} difficultyGrade={difficultyGrade} workloadGrade={workloadGrade} />
            <CourseAverage average={average} />
          </div>
        </div>
      </div>
      <CourseReq cred={cred} preq={preq} creq={creq} courseId={courseId} desc={desc} depn={depn} />
      <ReviewSection courseId={courseId} reviews={reviews} togglePopup={props.togglePopup} />
    </>
  );
}

export default CoursePage;