import React from 'react';
import Button from '@mui/material/Button';
import FeedbackIcon from '@mui/icons-material/Feedback';

// Function to handle the feedback button click
const handleBugButton = () => {
  window.open('https://docs.google.com/forms/d/1X1BFwopx9KTCzWm4TauzPqpuep-KqZWJHUmtlF8D29c/edit', '_blank');
};

// FeedbackButton component
export default function FeedbackButton() {
  return (
    <Button
      key="feedbackBug"
      variant="text"
      onClick={handleBugButton}
      sx={{ padding: '10px', color: 'white', backgroundColor: 'linear-gradient(to right, #2E8BFF, #3A9FFC, #66B5FF)', '&:hover': { backgroundColor: '#2A7BCC' } }}
    >
      <span style={{ marginLeft: 'auto' }}>Feedback & Bugs &nbsp;</span>
      <FeedbackIcon />
    </Button>
  );
}
