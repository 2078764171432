import React, { useState } from "react";

export function RatingField(props) {
  const ratingStyle = {
    backgroundColor: `#${props.mainBgColor}`
  };

  const inputRatingStyle = {
    backgroundColor: `#${props.inputBgColor}`
  };

  // State to track if the input is focused
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div style={ratingStyle} className="ratingField">
      <h2>{props.title}</h2>
      <input
        type="number"
        style={inputRatingStyle}
        placeholder={isFocused ? "" : props.placeholder} // Clear placeholder when focused
        value={props.rating === -1 ? "" : props.rating}
        onChange={(e) => props.setRating(e.target.value)}
        onFocus={() => setIsFocused(true)} // Set isFocused to true when input is focused
        onBlur={() => setIsFocused(false)} // Set isFocused to false when input loses focus
      />
    </div>
  );
}
