import React, {useState} from "react";

export default function Resource({ resourceName, url, color, title }) {

    const courseStyle = {
        paddingTop: "2.5rem",
        paddingBottom: "2.5rem",
        width: "100%",
        borderRadius: "0px 5px 0px 5px",
        fontSize: "2.5rem",
        textAlign: "center",
        fontWeight: "bold",
        background: `linear-gradient(to right,  #${color} 5px, transparent 7px)`,
        color: `#${color}`,
        transition: "transform 0.07s ease, background-color 0.5s, box-shadow 0.5s ease",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        backgroundColor: "white",
    };

    const resourceTitleStyle = {
        fontSize: "1rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginLeft: "12px",
        marginRight: "6px",
        marginTop: "0px",
    };

    const gradeStyle = {
        color: `#${color}`,
    }

    const courseHoverStyle = {
        ...courseStyle,
        transform: "scale(1.05)",
        cursor: "pointer",
        backgroundColor: `#${color}`,
        color: "#fbfbfb",
    }

    const defaultTextStyle = {
        color: `#${color}`,
        textDecoration: "none"
    }

    const changedTextStyle = {
        color: "white",
        textDecoration: "none"
    }
    
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const resourceNameStyle = {
        margin: 0
    }

    const containerStyle = {
        ...courseStyle,
        ...(isHovered ? courseHoverStyle : {}),
    };

    const handleClick = () => {
        window.open(url, "_blank");
    };

    return (
        <div
            style={containerStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <a
                style={isHovered ? changedTextStyle : defaultTextStyle}
            >
                <p style={resourceNameStyle}>{resourceName}</p>
                <p style={resourceTitleStyle}>{title}</p>
            </a>
        </div>
    );
}