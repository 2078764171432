import React, { useState, useEffect, useRef } from 'react';
import Section from '../home/Section';
import ResourceSection from '../home/ResourceSection';
import Tooltip from '@mui/material/Tooltip';
import '../../App.css';
import '../../style/Section.css';
import { CircularProgress } from '@mui/material';
import { HomeSearchBar } from '../home/HomeSearchBar';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

function HomePage() {
  const [boosterCourses, setBoosterCourses] = useState([]);
  const [adventuresCourses, setAdventuresCourses] = useState([]);
  const [bewareCourses, setBewareCourses] = useState([]);
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rippleEffect, setRippleEffect] = useState({ x: -1, y: -1 });

  async function fetchCourses(url) {
    const response = await fetch(url, {
      method: "GET"
    });
    return response.json();
  }
  
  useEffect(() => {
    async function fetchCourseContent() {
      setIsLoading(true);
      try {
        const [boosters, adventures, beware] = await Promise.all([
          fetchCourses("/api/courses/boosters/"),
          fetchCourses("/api/courses/adventures/"),
          fetchCourses("/api/courses/beware/")
        ]);
        setBoosterCourses(boosters);
        setAdventuresCourses(adventures);
        setBewareCourses(beware);
        setResources(resourcesDataArr);
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchCourseContent();
  }, []);

  const resourcesDataArr = [
    {
      name: "UBCGrades",
      url: "https://ubcgrades.com/",
      color: "e9566e",
      title: "Low Grade Justified"
    },
    {
      name: "Workday Calendar",
      url: "https://chromewebstore.google.com/detail/gonljejijjjmjccdbjokcmmdfmlincmh?hl=en",
      color: "185abd",
      title: "Readable Calendar on Workday"
    },
    {
      name: "Quadle",
      url: "https://quadle.net/",
      color: "2541b2",
      title: "Study Spaces on Campus"
    },
    {
      name: "UBC Explorer",
      url: "https://ubcexplorer.io/",
      color: "232f3e",
      title: "Informative Course Information"
    },
    {
      name: "UBCScheduler",
      url: "https://ubcscheduler.ca/",
      color: "00A7e1",
      title: "Course Planning Made Easy"
    }
  ];

  const handleCanvasClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setRippleEffect({
      x: event.clientX - rect.left,
      y: event.clientY - rect.top
    });
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <CircularProgress />
        <p>Loading courses...</p>
      </div>
    );
  }

  return (
    <div>
      <div className="main-container">
        <div className="homeImageContainer">
          <div className="homeTitle">
            <h1> &lt; insert cool title here &gt; <RocketLaunchIcon style={{ fontSize: "72px" }} /></h1>
          </div>
          <HomeSearchBar />
        </div>
        <div className="homeCourseSections">
          <SectionWithTitleTooltip
            sectionTitle={"Adventures ⛰"}
            displayCourses={adventuresCourses}
            tooltipTitle="Good reviews, not necessarily easy courses"
          />
          <SectionWithTitleTooltip
            sectionTitle={"Boosters 💯"}
            displayCourses={boosterCourses}
            tooltipTitle="Need I say more?"
          />
          <SectionWithTitleTooltip
            sectionTitle={"Beware ⚠️"}
            displayCourses={bewareCourses}
            tooltipTitle="These courses have low experience grade. However, that could only be from a few reviews. We recommend finding more information on the courses and professors"
          />
          <ResourceSectionWithTitleTooltip
            sectionTitle={"Resources 📚"}
            displayResources={resources}
            tooltipTitle={"Cool stuff from UBC students"}
          />
        </div>
      </div>
    </div>
  );
}

function SectionWithTitleTooltip({ sectionTitle, displayCourses, tooltipTitle }) {
  return (
    <div className="sectionTitle">
      <Tooltip title={tooltipTitle} placement="bottom-start">
        <span className="title-tooltip">{sectionTitle}</span>
      </Tooltip>
      <div>
        <Section displayCourses={displayCourses} />
      </div>
    </div>
  );
}

function ResourceSectionWithTitleTooltip({ sectionTitle, displayResources, tooltipTitle }) {
  return (
    <div className="sectionTitle">
      <Tooltip title={tooltipTitle} placement="bottom-start">
        <span className="title-tooltip">{sectionTitle}</span>
      </Tooltip>
      <div>
        <ResourceSection displayResources={displayResources} />
      </div>
    </div>
  );
}

export default HomePage;