export function SearchResult(props) {
    const courseId = `${props.dept}${props.courseNum}`;
    const courseUrl = `/${courseId}`;

    const handleClick = () => {
        window.location.href = courseUrl;
    };

    return (
        <div className={`searchResult ${props.isSelected ? 'selected' : ''}`} onClick={handleClick} role="link" tabIndex={0}>
            <p>{courseId}</p>
        </div>
    );
}