import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  zIndex: 2100,
}));

export default function AboutUs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button 
        variant="text" className="aboutUs" sx={{ padding: "10px", color: 'white', backgroundColor: 'linear-gradient(to right, #2E8BFF, #3A9FFC, #66B5FF)',
        '&:hover': {backgroundColor: '#2A7BCC',},}} 
        onClick={handleClickOpen}
        justifyContent="flex-end"
      >
        <span style={{ marginLeft: 'auto' }}>About &nbsp; </span>
        <InfoIcon/>
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          About
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <div className="close">
            <CloseIcon />
          </div>
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom style={{ marginBottom: '20px' }}>
            Hello hello,
          </Typography>
          <Typography gutterBottom style={{ marginBottom: '20px' }}>
            If you are reading this, thank you and welcome to UBC Courses! We developed this as a small project for more informed course-taking decisions.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: '20px' }}>
            We are two UBC students who wanted to create something that would help other students. Our hope is that this will you the courses you didn't know you need.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: '20px' }}>
            Let us know what features you would like to see with the feedback button - yes we do read them!
          </Typography>
          <Typography gutterBottom style={{ marginBottom: '0' }}>
            Roboto,
          </Typography>
          <Typography gutterBottom>
            Khoa & Andrew
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}