import React, { useEffect, useState } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

function RedditThreads({ courseCode, courseNum }) {
  const [redditResult, setRedditResult] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: {
            data: { children: results },
          },
        } = await axios.get(
          `https://www.reddit.com/r/ubc/search.json?q=${courseCode}%20${courseNum}&restrict_sr=on`
        );

        const json = results
          .sort((a, b) => (a.data.ups < b.data.ups ? 1 : -1))
          .map((posting) => {
            const { title, ups, created_utc, permalink, name } = posting.data;
            return {
              name,
              title,
              upvotes: ups,
              date_created: new Date(created_utc * 1000).toISOString().split("T")[0], // Format date as YYYY-MM-DD
              url: `https://www.reddit.com${permalink}`,
            };
          });

        setRedditResult(json);
      } catch (error) {
        console.error("Error fetching Reddit threads:", error);
        setRedditResult([]);
      }
    })();
  }, [courseCode, courseNum]);

  const handleCardClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="reddit-threads-container" style={{ marginTop: "20px", marginBottom: "20px" }}>
      {redditResult.length !== 0 ? (
        <>
          {redditResult.map((entry, index) => (
            <Paper
              key={index}
              elevation={3}
              style={{
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
              sx={{
                boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => handleCardClick(entry.url)}
            >
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    color: "#FF5700",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "0.5rem",
                    paddingRight: "1rem",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  {entry.upvotes}⬆
                </span>
                <div style={{ alignItems: "center" }}>
                  <Typography component="div" style={{ fontSize: "1rem", fontWeight: "500" }}>
                    {entry.title}
                  </Typography>
                  <Typography style={{ display: "flex", alignItems: "center", fontWeight: "400", fontSize: "0.8rem" }}>
                    {entry.date_created}
                  </Typography>
                </div>
              </div>
            </Paper>
          ))}
          <p style={{ color: "#303030" }}>
            Many thanks to <a href="https://github.com/MrBenC88" target="_blank" style={{ color: "#303030" }}>Ben</a> for this feature 🐐
          </p>
        </>
      ) : (
        <p className="emptyMessage">No relevant threads found 🙁</p>
      )}
    </div>
  );
}

export default RedditThreads;