import React, { useState, useEffect, useRef } from "react";
import "../../style/writeReview/WriteReviewPopup.css";

export function SearchCourseField(props) {
    const [searchCourseInput, setSearchCourseInput] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [boldInput, setBoldInput] = useState(false);
    const [selectedResultIndex, setSelectedResultIndex] = useState(-1);
    const inputRef = useRef(null);
    const selectedResultRef = useRef(null);

    useEffect(() => {
        const pathname = window.location.pathname;
        const lastSlashIndex = pathname.lastIndexOf("/");
        const potentialCourseId = pathname.substring(lastSlashIndex + 1);

        // Validate that the course ID starts with a letter and ends with a number
        const courseIdRegex = /^[a-zA-Z][a-zA-Z0-9]*[0-9]$/;
        if (courseIdRegex.test(potentialCourseId)) {
            setSearchCourseInput(potentialCourseId.toUpperCase());
        }
    }, []);

    useEffect(() => {
        if (searchCourseInput.trim() === "") {
            setSearchResults([]);
            return;
        }

        const debouncedSearch = setTimeout(() => {
            searchCourse(searchCourseInput)
                .then((data) => {
                    if (data.length === 1 && searchCourseInput.toLowerCase() === data[0].dept_id + data[0].course_number) {
                        handleClick(data[0].dept_id + data[0].course_number);
                        setBoldInput(true);
                        return;
                    }
                    setSearchResults(data);
                    setBoldInput(false);
                    setSelectedResultIndex(-1);
                })
                .catch((e) => console.log(e));
        }, 250);
        return () => clearTimeout(debouncedSearch);
    }, [searchCourseInput]);

    useEffect(() => {
        if (selectedResultIndex >= 0 && selectedResultIndex < searchResults.length) {
            inputRef.current.value = searchResults[selectedResultIndex].dept_id + searchResults[selectedResultIndex].course_number;
            selectedResultRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    }, [selectedResultIndex]);

    function sanitizeSearchInput(input) {
        let safeInput = input.replace(/\s/g, '');
        safeInput = safeInput.replace(/<[^>]*>?/gm, '');
        return encodeURIComponent(safeInput);
    }

    async function searchCourse(query) {
        if (query.length > 15) return [];
        const baseURL = "/api/courses/search/?";
        const queryParams = `id=${sanitizeSearchInput(query.toLowerCase())}`;
        const fetchURL = baseURL + queryParams;
        const response = await fetch(fetchURL);
        return response.json();
    }

    function handleClick(courseId) {
        props.setCourse(courseId);
        setSearchCourseInput(courseId);
        setSearchResults([]);
    }

    function handleKeyDown(e) {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            setSelectedResultIndex((prevIndex) => (prevIndex === 0 ? searchResults.length - 1 : prevIndex - 1));
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            setSelectedResultIndex((prevIndex) => (prevIndex === searchResults.length - 1 ? 0 : prevIndex + 1));
        } else if (e.key === "Enter") {
            e.preventDefault();
            if (selectedResultIndex >= 0 && selectedResultIndex < searchResults.length) {
                handleClick(searchResults[selectedResultIndex].dept_id + searchResults[selectedResultIndex].course_number);
            }
        }
    }

    return (
        <div>
            <div className="inputField">
                <h2>Course</h2>
                <input
                    type="text"
                    placeholder="CPSC110"
                    value={searchCourseInput.toUpperCase()}
                    onChange={(e) => {
                        setSearchCourseInput(e.target.value);
                        setSelectedResultIndex(0); // Reset selected result index when input changes
                    }}
                    onKeyDown={handleKeyDown}
                    style={{ fontWeight: boldInput ? 'bold' : 'normal', textTransform: 'uppercase' }}
                    ref={inputRef}
                />
                <div className={searchResults.length === 0 ? "searchResultsEmpty" : "searchResults"}>
                    {searchResults.map((result, index) => {
                        return (
                            <div
                                key={result.id}
                                className={index === selectedResultIndex ? "courseSearchResult selected" : "courseSearchResult"}
                                onClick={() => handleClick(result.dept_id + result.course_number)}
                                ref={index === selectedResultIndex ? selectedResultRef : null}
                            >
                                {result.dept_id.toUpperCase() + result.course_number}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}