function Grade(props) {
    return (
        <div className = "grade">
            <div className="experienceGradeContainer">
                <h3>EXPERIENCE</h3>
                <h2>{props.experienceGrade.toFixed(1)}/5</h2>
            </div>
            <div className="difficultyGradeContainer">
                <h3>DIFFICULTY</h3>
                <h2>{props.difficultyGrade.toFixed(1)}/5</h2>
            </div>
            <div className ="workloadGradeContainer">
                <h3>WORKLOAD</h3>
                <h2>
                    {props.workloadGrade.toFixed(1)}
                    <span className="workloadText"> hr/wk</span>
                </h2>
            </div>
        </div>
    );
}

export default Grade;