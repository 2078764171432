import React from "react";

export function ProfessorList(props) {
    return (
        <div className="professorListContainer">
            {props.professorList.map((professor) => {
                return (
                    <div key={professor.id} className="professor" onClick={() => props.removeProfessor(professor.id)}>
                        <p>{professor.full_name}</p>
                    </div>
                );
            })}
        </div>
    );
}