import React, { useContext } from 'react';
import '../../style/Navbar.css';
import { SearchBar } from './SearchBar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useLocation } from 'react-router-dom';
import GoogleLoginButton from '../oAuth/GoogleLoginButton';
import { AuthContext } from '../oAuth/Auth.js';
import AboutUs from './AboutUs.js';
import Feedback from './Feedback.js';

const buttonStyles = {
  padding: '10px',
  color: 'white',
  backgroundColor: 'linear-gradient(to right, #2E8BFF, #3A9FFC, #66B5FF)',
  '&:hover': { backgroundColor: '#2A7BCC' },
};

function Navbar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthed } = useContext(AuthContext);

  const isHomePage = location.pathname === '/';
  const isAccountPage = location.pathname === '/account';

  const handleNavigationClick = () => {
    if (isAccountPage) {
      navigate('/');
    } else {
      navigate('/account');
    }
  };
  
  return (
    <div className="navBarContainer">
      <a href="/" className="navBarChild">
        <h3>UBC COURSES <RocketLaunchIcon style={{ marginBottom: "-4px" }} /></h3>
      </a>
      {!isHomePage && (
          <div style={{ flexGrow: 1, marginRight: '30px' }}>
            <SearchBar />
          </div>
      )}
      <div>
        {isHomePage && isAuthed && (
          <>
            <Button onClick={props.togglePopup} variant="text" sx={buttonStyles} className="containedText">
              Write Course Review &nbsp;<RateReviewIcon/>
            </Button>
          </>
        )}
        {!isAuthed ? (
          <>
            <GoogleLoginButton sx={buttonStyles} />
          </>
        ) : (
          <>
            <Button onClick={handleNavigationClick} variant="text" sx={buttonStyles} className="containedText">
              {isAccountPage ? 'Home' : 'Account'} &nbsp;{isAccountPage ? <HomeIcon/> : <AccountBoxIcon/>}
            </Button>
          </>
        )}
        <Feedback sx={buttonStyles} />
        <AboutUs sx={buttonStyles} />
      </div>
    </div>
  );
}

export default Navbar;