import './App.css';
import Navbar from './components/navbar/Navbar';
import { Route, Routes } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import CoursePage from './components/pages/CoursePage';
import InfoPage from './components/pages/InfoPage';
import { WriteReviewPopup } from './components/writeReview/WriteReviewPopup';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {AuthContext, UserContext} from './components/oAuth/Auth';
import { useLocation } from 'react-router-dom';

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.gtag('config', 'G-9BRYHMK4VT', {
      page_path: location.pathname + location.search,
    });
  }, [location]);
  
  function handleTogglePopup() {
    setIsPopupOpen(!isPopupOpen);
  }
  
  const isMobileDevice = /Mobi/i.test(navigator.userAgent);

  // Authentication
  const [isAuthed, setAuthed] = useState(false);
  const [userId, setUserId] = useState(-1);

  useEffect(() => {
    isAccessTokenValid();
  }, []);

  async function isAccessTokenValid() {
    const response = await fetch("/api/auth/verify/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    });

    if (response.ok) {
      const parsedRes = await response.json();
      console.log(parsedRes);
      setUserId(parsedRes.id);
      setAuthed(true);
    } else {
      setAuthed(false);
    }
  }

  return (
    <UserContext.Provider value={{userId, setUserId}}>
    <AuthContext.Provider value={{isAuthed, setAuthed}}>
    <div>
      {isMobileDevice && (
        <div className="mobileAlert" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '12000', textAlign: 'center' }}>
          <Alert severity="warning">
            <AlertTitle>Mobile Version Not Supported</AlertTitle>
            Mobile devices are currently not supported. Please view on a computer for the best experience.
          </Alert>
        </div>
      )}
      <ToastContainer />
      <Navbar togglePopup={handleTogglePopup} />
      {isPopupOpen && <WriteReviewPopup togglePopup={handleTogglePopup} />}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:courseId" element={<CoursePage togglePopup={handleTogglePopup} />}/>
        <Route path="/account" element={<InfoPage />} />
      </Routes>
    </div>
    </AuthContext.Provider>
    </UserContext.Provider>
  );
}

export default App;