function CourseName(props) {
    
    return (
            <div className="courseInfo">
                <div className="codeContainer">
                    <h1 className="code">{props.courseId.toUpperCase()}</h1>
                </div>
                <div className="nameContainer">
                    <h3 className="name"> {props.courseName || "Course Title Unavailable"}</h3>
                </div>
            </div>
    );
}

export default CourseName;
