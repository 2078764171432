import Mic from "@mui/icons-material/Mic";
import MicNoneIcon from '@mui/icons-material/MicNone';
import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

export default function VoiceButton({setComments, isVoiceActive, setIsVoiceActive}) {
    window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const [transcribedText, setTranscribedText] = useState("");
    const recognitionRef = useRef(new window.SpeechRecognition());

    useEffect(() => {
        // Updates parent input field
        setComments(transcribedText);
    }, [transcribedText]);
    
    const activateVoice = (recognition) => {
        if ('SpeechRecognition' in window) {
            try {
                recognition.continuous = true;
                recognition.interimResults = false;
                recognition.lang = 'en-US';

                recognition.start();

                recognition.onstart = function() {
                    console.log("Started transcription.");
                };

                recognition.onresult = function(event) {
                    const eventsLen = event.results.length;
                    const transcript = event.results[eventsLen - 1][0].transcript;
                    setTranscribedText(prevText => prevText + transcript) // Sets content of comments input field from parent
                    // console.log("Added to transcription:", transcript);
                };

                recognition.onspeechend = function() {
                    if (isVoiceActive) {
                        recognition.start();
                        console.log("Continuing to listen...");
                    }
                };

                recognition.onerror = function(event) {
                    setIsVoiceActive(false);
                    toast.error("Microphone permissions are required to use this feature.", {
                        className: "toastMessage",
                        hideProgressBar: false,
                        autoClose: 4000,
                        position: "bottom-right"
                    });
                    console.error("An error occured while transcribing:", event.error)
                };
            } catch (err) {
                console.log("An error occured in speech recognition:", err);
            }
        } else {
            console.log("Browser does not support speech recognition.");
        }
    };

    const summarizeText = async (text) => {
        try {
            const response = await axios.post("/api/summarize", {
                content: text
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return response.data;
        } catch (err) {
            console.log(err);
        }
    };

    const handleClick = async () => {
        const recognition = recognitionRef.current;

        if (!isVoiceActive) {
            setTranscribedText("");
            setIsVoiceActive(true);
            activateVoice(recognition);
        } else {
            recognition.stop();
            if (transcribedText.length > 0) {
                const transcript = transcribedText;
                setTranscribedText("Summarizing transcription...");
                const summary = await summarizeText(transcript);
                setTranscribedText(summary);
            }
            setIsVoiceActive(false);
        }
    };

    const micOn = (
        <>
            Click to end transcription
            <Mic/>
        </>
    );

    const micOff = (
        <>
            Review using your voice
            <MicNoneIcon/>
        </>
    );

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
            <Button onClick={handleClick}>
                {!isVoiceActive ?  micOff : micOn}
            </Button>
            {!isVoiceActive ?
                ""
                :
                <p style={{marginBottom: "0.5rem", marginTop: "0.25rem", paddingLeft: "0.5rem", fontSize: "0.8rem", color: "gray"}}>
                    * Speak naturally and pause as needed. Don't worry about perfection—we'll summarize it for you when you're done.
                </p>
            }
        </div>
    );
}